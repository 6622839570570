import './styles.scss';

import { register, SwiperContainer, SwiperSlide } from 'swiper/element';
import { Autoplay }                     from 'swiper/modules';
import { SwiperOptions }                from 'swiper/types/swiper-options';

import { Breakpoint } from '@/modules/hde-app';

register();

const swiperEl = document.querySelector('swiper-container.header-swiper') as SwiperContainer;
const slides = swiperEl?.querySelectorAll('swiper-slide') as NodeListOf<SwiperSlide>;

if (swiperEl) {
    const swiperOptions: SwiperOptions = {
        // Default settings
        enabled: true,

        modules: [Autoplay],

        direction:     'horizontal',
        speed:         500,
        slidesPerView: 1,

        centeredSlides:           true,
        centeredSlidesBounds:     true,
        centerInsufficientSlides: true,

        autoplay: {
            delay:                3000,
            disableOnInteraction: false,
        },

        breakpoints: {
            // Settings when breakpoint is >= MD
            [Breakpoint.MD]: {
                slidesPerView: slides.length,
            },
        },
    };

    Object.assign(swiperEl, swiperOptions);

    function handleSwiperResize(): void {
        if (window.innerWidth < Breakpoint.MD) {
            setTimeout(() => {
                swiperEl.swiper.slideTo(0, 0, false);
            }, 50);
        }
    }

    swiperEl.addEventListener('swiperresize', handleSwiperResize);
    swiperEl.initialize();
}
