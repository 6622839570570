<template>
    <BaseModal ref="baseModalRef"
               :active="active"
               :is-dismissible="true"
               :allow-closing-on-backdrop-click="true"
               @modal:dismiss="dismiss()">
        <template v-slot:header>
            <h3 class="modal-title font-weight-semi-bold" id="deleteAddressConfirmationModal">
                {{ $translate('my.modal.deleteSuccess.header', 'account') }}
            </h3>
        </template>
        <template v-slot:body>
            <p><strong>{{ $translate('my.modal.deleteSuccess.message', 'account') }}</strong></p>
            <p v-html="$translate('my.modal.deleteSuccess.messageExtra', 'account')" />
        </template>
        <template v-slot:footer>
            <button type="button"
                    @click.prevent="dismiss()"
                    class="btn"
                    :class="[isHtde ? 'btn--raised btn--raised__primary' : 'btn-default']">
                {{ $translate('my.modal.confirm', 'account') }}
            </button>
        </template>
    </BaseModal>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';

import { ShopCode }     from '@/types/globals';
import BaseModal        from '@/vue/components/modals/BaseModal.vue';
import useAddressStore  from '@/vue/stores/address-store';
import useCartStore     from '@/vue/stores/cart-store';
import useCheckoutStore from '@/vue/stores/checkout-store';
import useCountryStore  from '@/vue/stores/country-store';
import useCustomerStore from '@/vue/stores/customer-store';
import useOrderStore    from '@/vue/stores/order-store';
import useResellerStore from '@/vue/stores/reseller-store';

const active       = ref<boolean>(false);
const baseModalRef = ref<InstanceType<(typeof BaseModal)>>();

onMounted(() => {
    nextTick(() => {
        active.value = true;

        const stores = [
            useAddressStore(),
            useCartStore(),
            useCheckoutStore(),
            useCountryStore(),
            useCustomerStore(),
            useOrderStore(),
            useResellerStore(),
        ];

        for (const store of stores) {
            store.reset();
        }
    });
});

const isHtde = computed(() => window.shopShortcode === ShopCode.HTDE);

function dismiss(): void {
    active.value = false;
}
</script>
